<template>
  <div class="payment-merchants-overview">
    <v-toolbar flat dense class="payment-merchants-toolbar">
      <v-spacer />
      <div
        :title="
          availableProviders.length > 0
            ? 'Create a new merchant'
            : 'No available providers to create a new merchant'
        "
      >
        <v-btn
          color="primary"
          class="ml-3"
          data-test-id="newPaymentMerchantBtn"
          :disabled="createMerchantDisabled"
          @click="createMerchant"
        >
          <div>New Merchant</div>
        </v-btn>
      </div>
    </v-toolbar>
    <v-data-table
      disable-pagination
      hide-default-footer
      class="payment-merchants-table"
      fixed-header
      single-select
      show-expand
      multi-sort
      data-test-id="paymentMerchantTable"
      item-key="name"
      :options.sync="options"
      :items-per-page="-1"
      :height="tableHeight"
      :items="paymentMerchants"
      :headers="headers"
      :loading="runningAction"
      :item-class="getItemClass"
      :expanded.sync="expanded"
    >
      <!-- eslint-disable-next-line -->
      <template #group.header="{ group, isOpen, toggle }">
        <td :colspan="headers.length" :data-test-id="'group_header_' + group">
          <div class="d-flex align-center pa-1">
            <v-icon
              @click="toggle"
              :data-test-id="'group_' + group + '_toogle_btn'"
            >
              {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
            </v-icon>
            <div class="ml-3 font-weight-medium text-truncate">
              {{ group }}
            </div>
          </div>
        </td>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.invalid="{ item }">
        <ViolationAlert v-if="!!item.violation" :violation="item.violation" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          icon
          v-if="isExpanded"
          @click="expand(false)"
          :data-test-id="'payment_merchant_' + item.name + '_hide_btn'"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          icon
          v-else
          @click="expand(true)"
          :data-test-id="'payment_merchant_' + item.name + '_expand_btn'"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1" class="pa-3">
          <PaymentMerchant
            fixed-header
            :merchant="item"
            :providers="paymentProviders"
            :data-test-id="'merchantDetail_' + item.name"
            @save="validateMerchant(item, $event)"
            @delete="loadPaymentMerchants"
          />
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="showMerchantDialog">
      <PaymentMerchant
        v-if="showMerchantDialog"
        data-test-id="newMerchantDialog"
        :providers="availableProviders"
        @close="closeDialog()"
        @save="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import PaymentMerchant from "./PaymentMerchant";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";

import configServiceMixin from "mixins/config-service-mixin";
import dataEditorMixin from "mixins/data-editor-mixin";
import paymentMixin from "mixins/payment-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";
export default {
  mixins: [paymentMixin, configServiceMixin, sortQueryMixin],

  inject: ["getEditorHeight"],

  components: {
    PaymentMerchant,
    ViolationAlert,
  },
  data() {
    return {
      paymentMerchants: [],
      expanded: [],
      runningAction: false,
      paymentProviders: [],
      showMerchantDialog: false,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
    };
  },

  mounted() {
    const routeQuery = this.$route.query;
    let sort = routeQuery.sort;

    if (sort) {
      const options = this.parseSortQueryToOptions(sort);
      this.options = Object.assign(this.options, options);
    }

    Promise.allSettled([
      this.loadPaymentMerchants(),
      this.loadPaymentProviders(),
    ]);
  },

  watch: {
    options(options) {
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({
        ...this.$route,
        query,
      });
    },
  },

  methods: {
    ...dataEditorMixin.methods,

    async loadPaymentMerchants() {
      try {
        this.runningAction = true;
        const lookupRes =
          await this.$store.$coreApi.coreConfigurationApi.configLookup(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern + ".*",
            {
              lookupRegion: ["data"],
              keyMatching: "completeKey",
            }
          );

        const paymentMerchants = (lookupRes ?? [])
          .filter(({ key }) => key.includes(this.keyPattern))
          .reduce((merchants, item) => {
            const merchantId = item.key.replace(this.keyPattern + ".", "");
            const inherited = item?.domain?.id !== this.selectedDomain;
            const model = {
              name: merchantId,
              inherited,
              ...item,
            };
            const idx = merchants.findIndex(({ name }) => name === merchantId);
            if (idx === -1) {
              merchants.push(model);
            } else {
              const existing = merchants[idx];
              if (existing.specificity < model.specificity) {
                merchants.splice(idx, 1, model);
              }
            }
            return merchants;
          }, []);

        for (let merchant of paymentMerchants) {
          await this.validateMerchant(merchant);
        }

        this.paymentMerchants = paymentMerchants;
      } finally {
        this.runningAction = false;
      }
    },

    async validateMerchant(merchant, { data } = {}) {
      /* 
        Validate the merchant's data with the preview enpoint 
        so that possible errors can be displayed in the table 
        row without opening the merchant details
      */
      if (data === undefined) {
        //if data is not given, try to load the current data from
        //the API endpoint
        const fullData =
          await this.$store.$coreApi.coreConfigurationApi.getData(
            this.selectedDomain,
            this.keySpace,
            merchant.key,
            {
              includeParents: false,
            }
          );

        data = fullData?.[0]?.data;
      }

      const preview =
        await this.$store.$coreApi.coreConfigurationApi.getPreviewMergedData(
          this.selectedDomain,
          this.keySpace,
          merchant.key,
          data
        );

      const result = await preview.json();
      const errors = result?.validationErrors;
      if (errors && errors.length > 0) {
        const { violation } = this.parseValidationErrorsToViolation(errors);
        this.$set(merchant, "violation", violation);
      } else {
        this.$delete(merchant, "violation");
      }

      //Add id and provider from the loaded data to the merchant model
      const id = result?.mergedData?.data?.id;
      const provider = result?.mergedData?.data?.provider;
      this.$set(merchant, "id", id);
      this.$set(merchant, "provider", provider);
      this.$set(merchant, "inherited", data === undefined || data === null);
    },

    getItemClass(merchantModel) {
      let itemClass = [
        "payment-merchant",
        "payment-merchant-" + merchantModel.id,
      ];
      if (merchantModel.violation) {
        itemClass.push("has-violation");
      }
      return itemClass.join(" ");
    },

    createMerchant() {
      this.showMerchantDialog = true;
    },

    async closeDialog({ keyPattern } = {}) {
      this.showMerchantDialog = false;
      if (keyPattern) {
        await this.loadPaymentMerchants();
        this.$nextTick(() => {
          this.expanded = this.paymentMerchants.filter(({ key }) => {
            return key === keyPattern;
          });
        });
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tableHeight() {
      return (
        this.getEditorHeight() -
        this.$vToolbarHeightDense -
        this.$vToolbarMarginBottom
      );
    },

    keyPattern() {
      return "core.payment.providers";
    },

    headers() {
      return [
        {
          text: "Merchant",
          value: "name",
          class: "merchant-header-name",
          cellClass: "merchant-name",
        },
        {
          text: "ID",
          value: "id",
          class: "merchant-header-id",
          cellClass: "merchant-id",
        },
        {
          text: "Provider",
          value: "provider",
          class: "merchant-header-provider",
          cellClass: "merchant-provider",
        },
        {
          text: "",
          value: "invalid",
          sortable: false,
          cellClass: "merchant-invalid",
        },
      ];
    },

    availableProviders() {
      return this.paymentProviders.filter((provider) => {
        return this.paymentMerchants.every(
          (merchant) =>
            merchant.provider !== provider && merchant.name !== provider
        );
      });
    },

    createMerchantDisabled() {
      return this.runningAction || this.availableProviders.length === 0;
    },
  },
};
</script>

<style scoped>
.payment-merchants-overview {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
  padding-top: 12px;
}

.payment-merchants-overview > .payment-merchants-toolbar {
  flex: 0 1 auto;
}

.payment-merchants-overview
  > .payment-merchants-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-row-group__header):not(.changed):hover {
  background: initial !important;
}

.payment-merchants-overview
  > .payment-merchants-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.has-violation {
  outline: 2px solid var(--v-error-base);
  outline-offset: -2px;
  color: var(--v-error-base);
  font-weight: bold;
}
</style>
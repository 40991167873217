<template>
  <DialogForm
    :fixed-header="fixedHeader"
    :title="title"
    :loading="runningAction"
    :flat="!isNew"
  >
    <template #actions="{ loading }">
      <v-btn
        class="ml-2"
        data-test-id="savePaymentMerchantBtn"
        :outlined="!isNew && !isDirty"
        :color="hasViolation ? 'error' : 'green'"
        :disabled="loading"
        @click="savePaymentMerchant"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          :color="isDirty ? 'white' : 'error'"
          alignment="left"
        />
        {{ isNew ? "Create" : "Save" }}
      </v-btn>

      <v-btn
        v-if="!isNew && !isInherited"
        class="ml-2"
        outlined
        color="red"
        data-test-id="deletePaymentMerchantBtn"
        :disabled="loading || isInherited"
        @click="deletePaymentMerchant"
      >
        Delete
      </v-btn>
      <v-btn
        v-else-if="isNew"
        text
        class="ml-2"
        data-test-id="cancelNewMerchantBtn"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
    </template>

    <template #form="{ loading }">
      <v-form ref="form">
        <div class="d-flex align-center mb-3">
          <div class="mx-3 v-input flex-grow-0">{{ providerTitle }}</div>
          <v-select
            v-model="provider"
            v-if="isNew || pspConfigModel"
            dense
            outlined
            hide-details="auto"
            data-test-id="merchantProviderInput"
            :rules="providerRules"
            :items="displayedProviders"
            :disabled="(!isNew && hasProvider) || loading"
          />
        </div>

        <DataEditor
          v-if="provider && pspConfigModel"
          :uimodel="pspConfigModel"
          :disabled="loading"
          @input="updatePspConfig"
          @restore="restorePspConfig(pspConfigModel)"
        />
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import configServiceMixin from "mixins/config-service-mixin";
import dataEditorMixin from "mixins/data-editor-mixin";
import DialogForm from "components/common/templates/DialogForm";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import DataEditor from "components/configuration/data/editors/DataEditor";
export default {
  mixins: [configServiceMixin],

  components: {
    DialogForm,
    ViolationAlert,
    DataEditor,
  },

  props: {
    merchant: {
      type: Object,
      required: false,
    },

    providers: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  provide() {
    return {
      getSource: this.getSource,
      getViolations: this.getViolations,
    };
  },

  data() {
    return {
      data: null,
      savedData: null,
      mergedData: null,
      schema: null,
      provider: null,
      pspConfigModel: null,
      providerTitle: null,
      runningAction: false,
      sources: [],
      violation: null,
      isDirty: false,
    };
  },

  watch: {
    data: {
      handler: function (data) {
        this.isDirty = !this.$isEqual(data, this.savedData);
      },
      deep: true,
    },

    async keyPattern() {
      await this.loadMerchantData();
      await this.$nextTick();
      this.validate();
    },
  },

  mounted() {
    this.loadMerchantData();
  },

  methods: {
    ...dataEditorMixin.methods,

    async loadMerchantData() {
      try {
        this.runningAction = true;

        const mergedSchemaRes =
          await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern
          );
        this.schema = mergedSchemaRes?.schema;

        if (this.isNew) {
          this.data = {
            id: null,
            provider: this.provider,
            pspConfig: {},
          };
        } else {
          const mergedDataRes =
            await this.$store.$coreApi.coreConfigurationApi.getMergedData(
              this.selectedDomain,
              this.keySpace,
              this.keyPattern,
              null,
              {
                query: "withInheritanceSources=true",
              }
            );

          this.mergedData = mergedDataRes?.data;
          this.sources = mergedDataRes?.sources ?? [];

          //retrieve data of this domain
          const fullData =
            await this.$store.$coreApi.coreConfigurationApi.getData(
              this.selectedDomain,
              this.keySpace,
              this.keyPattern,
              {
                includeParents: false,
              }
            );

          const data = fullData?.[0]?.data;
          //deep clone of data for usage and restore
          this.data = this.$cloneObject(data);
          this.savedData = this.$cloneObject(data);
          this.provider = this.mergedData?.provider;
        }
        this.buildMerchantModels();
      } finally {
        this.runningAction = false;
      }
    },

    buildMerchantModels() {
      const data = this.$cloneObject(this.data);
      const savedData = this.$cloneObject(this.savedData);
      const mergedData = this.$cloneObject(this.mergedData);

      const uimodel = {
        schema: this.schema,
        data,
        savedData,
        mergedData,
        path: "$",
        inheritable: !this.isNew,
        editable: true,
        domain: this.selectedDomain,
        dataLevel: 0,
        keySpace: this.keySpace,
        key: this.keyPattern,
      };

      const subUiModels = this.buildSubUimodels(uimodel);
      this.providerTitle = subUiModels?.provider?.label + "*";
      let pspConfigModel = subUiModels?.pspConfig;
      this.$set(pspConfigModel, "dataLevel", 0);
      this.$set(pspConfigModel, "isRoot", true);
      this.pspConfigModel = pspConfigModel;
    },

    updatePspConfig(data) {
      if (!this.data) this.data = {};
      this.$set(this.data, "pspConfig", data);
    },

    async savePaymentMerchant() {
      try {
        this.runningAction = true;
        this.violation = null;
        if (!this.validate()) {
          this.violation = {
            message: "At least one input field is invalid",
          };
          return;
        }

        let data = this.data;
        if (!this.hasProvider && this.provider) {
          this.$set(data, "provider", this.provider);
        }
        const pspConfig = this.data?.pspConfig;
        if (pspConfig) {
          this.$set(data, "id", pspConfig?.id ?? null);
        } else {
          data = null;
        }

        const id = this.isNew ? this.provider : this.merchant.name;
        const successMsg =
          ` Payment merchant <strong>` +
          id +
          `</strong> ` +
          (this.isNew ? "created" : "saved");

        const res = await this.upsertData(
          this.keySpace,
          this.keyPattern,
          data,
          successMsg
        );

        if (res?.status !== 200) {
          if (res?.violation) {
            this.violation = res.violation;
          }
          return;
        }

        this.$emit("save", {
          keyPattern: this.keyPattern,
          data,
        });

        if (!this.isNew) {
          await this.loadMerchantData();
        }
      } finally {
        this.runningAction = false;
      }
    },

    async deletePaymentMerchant() {
      const confirmed = await this.$confirm(
        "Delete merchant data?",
        `<p> 
            Are you sure you want to delete the data of merchant
            <strong> ` +
          this.merchant.name +
          ` </strong> ?
          </p>
          <p>
            <small>
              <i>If the merchant exists on parent level, its data will be inherited</i>
            </small>
          </p>
        `
      );
      if (!confirmed) return;

      try {
        this.runningAction = true;
        const successMsg =
          `Payment merchant <strong>` +
          this.merchant.name +
          `</strong> deleted`;

        const res = await this.deleteData(
          this.keySpace,
          this.keyPattern,
          successMsg
        );
        if (!res?.ok) return;
        this.$emit("delete");
      } finally {
        this.runningAction = false;
      }
    },

    validate() {
      const form = this.$refs.form;
      return this.$validateVForm(form);
    },

    restorePspConfig() {
      this.restoreProperty(this.pspConfigModel, this.data);
      this.buildMerchantModels();
    },
  },

  computed: {
    ...dataEditorMixin.computed,
    keyPattern() {
      if (this.isNew) {
        return "core.payment.providers." + this.provider;
      }
      return this.merchant.key;
    },

    keySpace() {
      return "Configuration";
    },

    isNew() {
      return !this.merchant;
    },

    title() {
      return this.isNew ? "New Merchant" : this.merchant.name;
    },

    hasViolation() {
      return !!this.violation;
    },

    hasProvider() {
      return !!this.mergedData?.provider;
    },

    displayedProviders() {
      return this.providers.filter((provider) => {
        return this.isNew || provider === this.merchant?.name;
      });
    },

    providerRules() {
      return [this.ruleSet.required({ strictRequired: true })];
    },

    isInherited() {
      return !!this.merchant?.inherited;
    },
  },
};
</script>

<style scoped>
</style>